export enum PermissionTypes {
  DASHBOARD = 'dashboard',

  STATISTICS = 'statistics',

  MOVIES = 'movies',
  MOVIES__LATEST = 'movies__latest',
  MOVIES__DATABASE = 'movies__database',
  MOVIES__ADD_REQUEST = 'movies__add_request',
  MOVIES__NOTED = 'movies__noted',
  MOVIES__REQUESTED = 'movies__requested',

  PLANNING = 'planning',

  TUBE = 'tube',

  KIOSKS = 'kiosks',
  KIOSKS__GENERAL = 'kiosks__general',
  KIOSKS__PRODUCTS = 'kiosks__products',
  KIOSKS__LAYOUTS = 'kiosks__layouts',

  REPORTS = 'reports',
  REPORTS__TB_DATA = 'reports__tb_data',
  REPORTS__FFA = 'reports__ffa',
  REPORTS__RAW_DATA = 'reports__raw_data',
  REPORTS__REPORTS_GENERAL = 'reports__reports_general',
  REPORTS__VDF = 'reports__vdf',

  ACCOUNT = 'account',

  ACCOUNT__PERSMISSION = 'account__persmission',

  ACCOUNT__CONTRACT = 'account__contract',
  ACCOUNT__CONTRACT__OVERVIEW = 'account__contract__overview',
  ACCOUNT__CONTRACT__COMPANIES = 'account__contract__companies',
  ACCOUNT__CONTRACT__ACTIVATION_ORDERS = 'account__contract__activation_orders',
  ACCOUNT__CONTRACT__INVOICES = 'account__contract__invoices',

  ACCOUNT__ADMINISTRATION = 'account__administration',
  ACCOUNT__ADMINISTRATION__CINEMAS = 'account__administration__cinemas',
  ACCOUNT__ADMINISTRATION__EMPLOYEES = 'account__administration__employees',
  ACCOUNT__ADMINISTRATION__DEVICES = 'account__administration__devices',
  ACCOUNT__ADMINISTRATION__TERMINALS = 'account__administration__terminals',

  ACCOUNT__CONFIGURATION = 'account__configuration',
  ACCOUNT__CONFIGURATION__PRICES = 'account__configuration__prices',
  ACCOUNT__CONFIGURATION__RELEASE_NOTES = 'account__configuration__release_notes',

  MARKETING = 'marketing',
  MARKETING___VOUCHERS_CARDS = 'marketing___vouchers_cards',
  MARKETING__VOUCHERS_CARDS__OVERVIEW = 'marketing__vouchers_cards__overview',
  MARKETING__VOUCHERS_CARDS__PRODUCT = 'marketing__vouchers_cards__product',
  MARKETING__VOUCHERS_CARDS__TYPES_AND_SERIES = 'marketing__vouchers_cards__types_and_series',
  MARKETING__VOUCHERS_CARDS__BONUS_PROGRAMS = 'marketing__vouchers_cards__bonus_programs',
  MARKETING__VOUCHERS_CARDS__TEMPLATES_AND_THEMES = 'marketing__vouchers_cards__templates_and_themes',
  MARKETING___EMAIL_MARKETING = 'marketing___email_marketing',
  MARKETING__EMAIL_MARKETING__PERSONALIZATION = 'marketing__email_marketing__personalization',
  MARKETING__EMAIL_MARKETING__RECEIVERS = 'marketing__email_marketing__receivers',
  MARKETING__EMAIL_MARKETING__CAMPAIGNS = 'marketing__email_marketing__campaigns',
  MARKETING__EMAIL_MARKETING__ANALYTICS = 'marketing__email_marketing__analytics',
  MARKETING___CUSTOMERS = 'marketing___customers',
  MARKETING__CUSTOMERS__ACCOUNTS = 'marketing__customers__accounts',
}
