import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppVersionStoreService } from '../services/AppVersionStoreService';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

  public constructor(
    private readonly _appVersionStoreService: AppVersionStoreService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const obj = {
      headers: req.headers
        .set('X-app', environment.app)
        .set('x-version-prefix', environment.versionPrefix)
        .set('x-gui-version', environment.version)
    };

    const request$ = next.handle(req.clone(obj));

    return request$.pipe(
      tap(data => {
        if (data instanceof HttpResponse) {
          this.setVersion(data);
        }
      }),
      catchError(data => {
        if (data instanceof HttpErrorResponse) {
          this.setVersion(data);
        }

        return throwError({
          ...data,
          requestMethod: req.method
        });
      })
    );
  }

  private setVersion(data: HttpErrorResponse | HttpResponse<any>): void {
    this._appVersionStoreService.version = data.headers.get('x-response-api-version') ?? '';
  }
}
