import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { StaticDependenciesService } from './StaticDependenciesService';
import { HubConnectionState } from '@microsoft/signalr';
import { ISignalRError } from '../interfaces';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/core/services/AuthService';
import { IdentityService } from './IdentityService';

export abstract class AbstractSignalRService {

  public static connections: AbstractSignalRService[] = [];

  protected readonly wsUrl = environment.wsUrl;

  private _connection: signalR.HubConnection;
  private _wsMethods: { name: string; method: (...args: any[]) => void; }[] = [];
  private _reconnectWithNewTokenAttempts = 0;
  private _reconnectAttempts = 0;
  private readonly _reconnectInterval = 5000;
  private _reconnectTimer: any;
  private readonly _authService = inject(AuthService);
  private readonly _identityService = inject(IdentityService);

  protected constructor(
    protected readonly hubUrl: string,
    protected readonly initMethod = 'initializeAsync'
  ) {}

  public start(): void {
    if (this._connection?.state !== HubConnectionState.Connected) {
      this._reconnectWithNewTokenAttempts = 0;
      this._reconnectAttempts = 0;
      
      if (this._reconnectTimer) {
        clearTimeout(this._reconnectTimer);
        this._reconnectTimer = null;
      }

      this._connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${ this.wsUrl }${ this.hubUrl }`)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.previousRetryCount < 10) {
              return 5000;
            } else {
              return 30000;
            }
          }
        })
        .build();

      AbstractSignalRService.connections.push(this);

      this.handleReconnect();
      this.startConnection();
      
      this.createMethod('disconnected', (arg: ISignalRError) => {
        if (arg) {
          if (arg.errorCode === 401 && this._reconnectWithNewTokenAttempts < 6) {
            this._authService.refreshToken().subscribe({
              next: res => {
                this._identityService.identity = {
                  ...this._identityService.identity,
                  accessToken: res.accessToken
                };

                this.reconnect();
                this._reconnectWithNewTokenAttempts++;
              },
              error: this._authService.logout.bind(this._authService)
            });
          } else {
            this._authService.logout();
          }
        }
      });
    }
  }

  private startConnection(): void {
    console.log(`Attempting to connect to ${this.hubUrl}...`);
    this._connection.start()
      .then(() => {
        console.log(`Successfully connected to ${this.hubUrl}`);
        this._reconnectAttempts = 0;
        this.invoke();
      })
      .catch(error => {
        console.error(`SignalR connection error for hub ${this.hubUrl}:`, error);
        
        this._reconnectAttempts++;
        const delay = Math.min(this._reconnectAttempts * 2000, 30000);
        
        console.log(`Attempting to reconnect to ${this.hubUrl} in ${delay/1000} seconds... (Attempt ${this._reconnectAttempts})`);
        
        this._reconnectTimer = setTimeout(() => {
          this.startConnection();
        }, delay);
      });
  }

  public createMethod(name: string, method: (...args: any[]) => void): void {
    this._connection.on(name, method);

    if (!this._wsMethods.find(x => x.name === name)) {
      this._wsMethods.push({ name, method });
    }
  }

  public stop(): void {
    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);
      this._reconnectTimer = null;
    }
    
    AbstractSignalRService.connections = AbstractSignalRService.connections.filter(
      connection => connection !== this
    );
    
    if (this._connection) {
      this._connection.stop().catch(error => {
        console.error(`Error stopping connection to ${this.hubUrl}:`, error);
      });
    }
    
    this._wsMethods = [];
  }

  public getConnectionState(): string {
    if (!this._connection) {
      return 'Not initialized';
    }
    return HubConnectionState[this._connection.state];
  }

  private invoke(): void {
    this._connection.invoke(this.initMethod, StaticDependenciesService.tokenService.token)
      .catch(error => {
        console.error(`Error invoking ${this.initMethod} method for hub ${this.hubUrl}:`, error);
        
        if (error && error.statusCode === 401) {
          this._authService.refreshToken().subscribe({
            next: res => {
              this._identityService.identity = {
                ...this._identityService.identity,
                accessToken: res.accessToken
              };
              
              setTimeout(() => {
                this.invoke();
              }, 1000);
            },
            error: this._authService.logout.bind(this._authService)
          });
        } else {
          this.startConnection();
        }
      });
  }

  private reconnect(): void {
    this.invoke();
    this._wsMethods.forEach(x => this._connection.on(x.name, x.method));
  }

  private handleReconnect(): void {
    this._connection.onreconnecting(error => {
      console.log(`Reconnecting to ${this.hubUrl}...`, error);
    });
    
    this._connection.onreconnected(connectionId => {
      console.log(`Reconnected to ${this.hubUrl} with connection ID: ${connectionId}`);
      this.reconnect();
    });
    
    this._connection.onclose((error) => {
      console.log(`Connection to ${this.hubUrl} closed.`, error);
      if (error) {
        console.error(`SignalR connection closed with error: ${error}`);
        
        this._reconnectTimer = setTimeout(() => {
          console.log(`Attempting to reconnect to ${this.hubUrl} after connection closed...`);
          this.start();
        }, this._reconnectInterval);
      }
    });
  }
}
