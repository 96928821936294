import { Apps } from '../app/core/enums';

export const environment = {
  production: true,
  isSW: false,
  version: 'v2.52.5',
  mandatorToken: 'CCAG',
  cloudFrontUrl: 'https://images.cinetixx.com',
  managerUrl: 'https://manager.cinetixx.de',
  apiUrl: 'https://api.backend.cinetixx.com',
  wsUrl: 'https://api.backend.cinetixx.com/signalr',
  appUrl: 'https://admin.cinetixx.de',
  app: Apps.Admin,
  brand: 'CX',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  dsn: 'https://609695911e154bbdc9550ac42e93ae29@o4508816761225216.ingest.de.sentry.io/4508817086611536',
  versionPrefix: '',
  trailersUrl: 'https://trailers.cinetixx.com/',
  releaseNotesUrl: 'https://cinetixx.de/assets/pdf/',
  postersLink: 'https://images.cinetixx.com/posters'
};
