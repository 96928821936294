import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import de from '@angular/common/locales/de';
import extraDe from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  BaseLayoutModule,
  CinetixxUiLibModule,
  CoreModule as CxCoreModule,
  NewVersionModalModule
} from '@cinetixx/cinetixx-ui';
import * as Sentry from "@sentry/angular-ivy";
import { DragulaModule } from 'ng2-dragula';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/core/services/AuthService';
import { CoreModule } from './core/core.module';
import { IdentityService } from './core/services/IdentityService';
import { StaticDependenciesService } from './core/services/StaticDependenciesService';
import { TableConfigDatabaseStrategy } from './core/strategies/TableConfigDatabaseStrategy';
import { TableStateDatabaseStrategy } from './core/strategies/TableStateDatabaseStrategy';
import { TableExportStrategy } from './exports/core/strategies/TableExportStrategy';
import { languagesSnippets$ } from './language/core/resolvers/languages-snippets.resolver';
import { LanguageModule } from './language/language.module';

export function initializeApp(authService: AuthService, identityService: IdentityService) {
  return () => {
    if (identityService.identity) {
      return authService.getStatus();
    }
    
    return Promise.resolve();
  };
}

const setLocales = (): void => {
  const lang = location.pathname.split('/')[1];

  Promise.all([
    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-extra" */

      `@/../node_modules/@angular/common/locales/${ lang }`
      ),
    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-extra" */

      `@/../node_modules/@angular/common/locales/extra/${ lang }`
      )
  ]).then(([module1, module2]) => registerLocaleData(module1.default, 'main', module2.default))
    .catch(err => registerLocaleData(de, 'main', extraDe));
};

setLocales();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AmChartsModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    CxCoreModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BaseLayoutModule,
    DragulaModule.forRoot(),
    LanguageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.isSW
    }),
    NewVersionModalModule
  ],
  providers: [
    StaticDependenciesService,
    {
      provide: LOCALE_ID,
      useValue: 'main'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, IdentityService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  public constructor(
    private readonly _staticDependenciesService: StaticDependenciesService,
  ) {
    languagesSnippets$.subscribe(() => {
      CinetixxUiLibModule.forRoot({
        transFunction: String().trans,
        app: environment.app,
        defaultTimeFormat: environment.defaultTimeFormat,
        defaultDateFormat: environment.defaultDateFormat,
        lang: StaticDependenciesService.languageStoreService.language.token,
        production: environment.production,
        cloudFrontUrl: environment.cloudFrontUrl,
        defaultTableStateStrategy: TableStateDatabaseStrategy,
        defaultTableConfigStrategy: TableConfigDatabaseStrategy<any>,
        defaultTableExportStrategy: TableExportStrategy,
        fallbackLanguage: environment.fallbackLanguage,
        brand: environment.brand
      });

      new CinetixxUiLibModule();
    });
  }
}
