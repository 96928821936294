export enum StoreCacheKeys {
  GetAllLanguages = 'getAllLanguages',
  GetAllSnippets = 'getAllSnippets',
  GetMyProfile = 'getMyProfile',
  GetMovieDiscriptors = 'getMovieDiscriptors',
  GetDistributors = 'getDistributors',
  GetDashboard = 'getDashboard',
  GetLatest = 'getLatest',
  GetGenres = 'getGenres',
  GetAgeRatings = 'getAgeRatings',
  GetVersionTypes = 'getVersionTypes',
  GetCastTypes = 'getCastTypes',
  GetMovieTypes = 'getMovieTypes',
  GetAwsDashboardUrl = 'getAwsDashboardUrl',
  CountriesMovie = 'countriesMovie',
  Countries = 'countries',
  GetAdmins = 'getAdmins',
  GetTerminalTypes = 'getTerminalTypes',
  GetKioskLayoutTypes = 'getKioskLayoutTypes',
  GetMandators = 'getMandators',
  GetTableExports = 'getTableExports',
  GetAllReportTypes = 'getAllReportTypes',
  GetMandatorFirms = 'getMandatorFirms',
  GetCities = 'getCities',
  GetAuditoriumTypes = 'getAuditoriumTypes',
}
